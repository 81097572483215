import axios from 'axios';
import { Message } from 'element-ui';

// 通用配置参数
const config = {
  timeout: 300000, // 请求超时时间
  baseURL: '/prod-api/api',
};

function httpRequest(options = {}) {
  const instance = axios.create(Object.assign(config, options));
  // 添加请求拦截器
  instance.interceptors.request.use(
    function(config) {
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
  // 添加响应拦截器
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
  return instance;
}

export default function request(options = {}) {
  return new Promise((resolve, reject) => {
    return httpRequest()(options)
      .then((res) => {
        const { code, data, msg = '请求出错' } = res.data;
        // 错误提示
        if (code !== 200) {
          Message.error({
            message: msg,
            duration: 1000,
          });
        }
        resolve(code === 200 ? data : false);
      })
      .catch((err) => {
        reject(err);
        Message.error({
          message: '请求出错',
          duration: 1000,
        });
      });
  });
}
